import React from 'react';
import GatsbyLink from '../src/components/GatsbyLink';
import './Logo.scss';

export const Logo = props => {
  return (
    <GatsbyLink to="/" className="logo">
      <svg width={578} height={91} viewBox="0 0 578 91" {...props}>
        <title>{"Hooker_White"}</title>
        <g fill="#EFF2EE" fillRule="nonzero">
          <path d="M55 80h10.913v1.7H57.34V84.1h8.159v1.581h-8.16v2.62H66V90H55zM98.088 80.067h3.748l7.866 8.188V80H112v9.933h-3.765l-7.902-8.154V90H98zM156.958 84.635v1.039a5.834 5.834 0 01-.46 3.067c-.34.562-.92.948-1.582 1.055a24.31 24.31 0 01-4.44.165c-1.54.104-3.086-.001-4.595-.313-.916-.273-1.582-1.04-1.701-1.962a12.294 12.294 0 01-.17-2.59c0-2.588.17-3.412.817-4.088a2.9 2.9 0 011.82-.808c1.65-.174 3.31-.234 4.968-.182 2.944 0 3.999.215 4.645.907.493.677.722 1.5.647 2.325h-2.28c0-.626-.102-.857-.392-1.072-.289-.214-.901-.362-2.858-.362-3.641 0-4.202 0-4.594.51-.391.512-.425.99-.425 2.507 0 1.913.119 2.572.544 2.918.426.347 1.004.462 3.743.462 2.74 0 3.318 0 3.76-.692.232-.425.327-.908.273-1.385h-4.475v-1.484l6.755-.017zM190 80h2v10h-2zM225 80.068h3.766l7.907 8.243V80H239v10h-3.784l-7.87-8.277V90H225zM272 80h10.913v1.7h-8.574V84.1h8.159v1.581h-8.16v2.62H283V90h-11zM315 80h10.913v1.7h-8.574V84.1h8.159v1.581h-8.16v2.62H326V90h-11zM358.033 80h8.282c1.657 0 2.502.204 3.032.8.5.693.726 1.556.63 2.414a3.372 3.372 0 01-.431 2.092 1.759 1.759 0 01-1.226.663c1.226.102 1.507.51 1.49 2.11V90h-2.269v-1.514c0-1.377-.348-1.7-1.822-1.7h-5.466V90H358l.033-10zm7.636 5.102c1.657 0 2.071-.306 2.071-1.514 0-1.207-.348-1.7-2.02-1.7h-5.434v3.18l5.383.034zM403.058 80H405v10h-2zM439 80.068h3.502l7.35 8.243V80H452v10h-3.518l-7.302-8.277V90H439zM497.96 84.652v1.039a5.793 5.793 0 01-.476 3.067 2.263 2.263 0 01-1.58 1.039c-1.47.19-2.955.245-4.436.165a17.031 17.031 0 01-4.589-.314 2.395 2.395 0 01-1.7-1.962 12.31 12.31 0 01-.17-2.589c0-2.589.17-3.413.817-4.09a2.894 2.894 0 011.818-.807 36.304 36.304 0 014.963-.182c2.94 0 3.993.215 4.64.907.492.677.72 1.5.645 2.325h-2.277c0-.626-.085-.857-.374-1.071-.289-.215-.918-.363-2.872-.363-3.637 0-4.198 0-4.589.511-.39.511-.425.99-.425 2.506 0 1.913.12 2.573.544 2.919.425.346 1.02.462 3.739.462 2.991 0 3.314 0 3.756-.693.23-.425.326-.907.272-1.385h-4.47v-1.484h6.764z" />
          <g>
            <path d="M0 64.28V0h18.1v25.31h46V0h17.35v64.28H64.07V39.37h-46v24.91zM106.9 6a9.72 9.72 0 013-3.37 13.87 13.87 0 014.25-2 18.72 18.72 0 014.91-.63h48.8a16.9 16.9 0 014.69.68 13.51 13.51 0 014.25 2.1 11.77 11.77 0 013.12 3.47 8.94 8.94 0 011.22 4.69v42.4a8.91 8.91 0 01-1.22 4.66 11.65 11.65 0 01-3.12 3.47 13.51 13.51 0 01-4.25 2.1c-1.523.45-3.102.68-4.69.68h-48.8a18.715 18.715 0 01-4.88-.63 13.64 13.64 0 01-4.25-2 9.69 9.69 0 01-3-3.36 10.25 10.25 0 01-1.12-5V10.94A10.21 10.21 0 01106.9 6zm57 8.1H123v36.12h40.89l.01-36.12zM205.6 6a9.59 9.59 0 013-3.37 13.87 13.87 0 014.25-2 18.718 18.718 0 014.9-.63h48.8c1.588.004 3.167.233 4.69.68a13.51 13.51 0 014.25 2.1 11.94 11.94 0 013.13 3.47 9 9 0 011.22 4.69v42.4a9 9 0 01-1.22 4.66 11.82 11.82 0 01-3.13 3.47 13.51 13.51 0 01-4.25 2.1c-1.522.451-3.102.68-4.69.68h-48.8a18.715 18.715 0 01-4.88-.63 13.64 13.64 0 01-4.25-2 9.56 9.56 0 01-3-3.36 10.25 10.25 0 01-1.13-5V10.94A10.21 10.21 0 01205.6 6zm57 8.1h-40.94v36.12h40.89l.05-36.12zM494.29 27.06h56.05l1.56-1.17v-11l-1.56-1.18h-56.05V0h67a6.05 6.05 0 012.88.83 12.46 12.46 0 012.79 2.1A12.71 12.71 0 01569 5.71c.514.875.8 1.866.83 2.88V29.8a9.82 9.82 0 01-1.42 5.37 11.29 11.29 0 01-3.71 3.66 16.37 16.37 0 01-5.18 2.05 26.46 26.46 0 01-5.81.64l23.64 22.76h-24.57L529 41.52h-17.23v22.76h-17.48V27.06zM396.91 49.46h75.22v14.87h-75.22zM319.91 25.3V0h-17.19v64.28h17.19V39.47zM379.72 0h-22.66l-32.4 32.14 32.4 32.14h22.66l-31.59-32.14zM396.91 25.24h55.6v14.02h-55.6zM396.83 13.63h75.22l.14-13.6h-75.21z" />
          </g>
        </g>
      </svg>
    </GatsbyLink>
  );
}
