import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Logo } from '../../../theme/Logo'
import HeaderNav from '../HeaderNav';
import Headroom from 'react-headroom';
import './Header.scss';

const isClient = typeof window !== 'undefined';

class Header extends Component {
  state = {
    contactActive: false,
    navActive: false,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  /* Small function to remove the nav-open
   * On a window resize.
   */
  handleResize = () => {
    if (window.innerWidth > 1080) {
      if(isClient) {
        let childrenDomElements = document.querySelectorAll('.children');
        for (let i = 0; i < childrenDomElements.length; i++) {
          if (childrenDomElements[i].classList.contains('open')) {
            childrenDomElements[i].classList.remove('open')
          }
        }
        if (document.body.classList.contains('nav-open')) {
          this.toggleNav();
        }
      }
    }
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  toggleNav = event => {
    const { navActive } = this.state;
    if (event) event.preventDefault();
    if (!navActive) {
      if (isClient) {
        document.body.classList.add('nav-open');
      }
      this.setState({
        contactActive: false,
        navActive: true,
        searchActive: false,
      });
    } else {
      if (isClient) {
        document.body.classList.remove('nav-open');
      }
      this.setState({ navActive: false });
    }
  };

  closeAll = event => {
    if (event) event.preventDefault();
    if (isClient) {
      document.body.classList.remove('nav-open');
    }
    this.setState({
      searchActive: false,
      navActive: false,
    });
  };

  render() {
    const {
      navActive,
    } = this.state;
    const {
      data,
    } = this.props;
    const {
      abovePrimaryNavigation,
      primaryNavigation,
      siteSettings,
      metaData,
      wordpressWpSettings
    } = data;
    const { options } = siteSettings
    const { quote, showQuoteButtonInHeader, quotePage } = options
    const { phone } = options.locations[0];
    const { baseUrl } = wordpressWpSettings;
    const { items: navItems } = primaryNavigation
    const { items: aboveNavItems } = abovePrimaryNavigation
    return (
      <Headroom disableInlineStyles={true} pinStart={50}>
      <header className={options.invertHeaderColours ? 'invert' : ''}>
        {options.navigationAbovePrimaryMenu &&
          <div className="above-header">
            <div className="inner">
              <HeaderNav active={navActive} className="above-head-navigation" navItems={aboveNavItems} baseUrl={baseUrl} toggleNav={this.toggleNav} />
            </div>
          </div>
        }
        <div className="header-main">
          <div className="wrap">
            <div className="inner">
              <Logo />
              <div className="nav">
                <nav className={`nav-inner${navActive ? ' active' : ''}`}>
                  {options.navigationAbovePrimaryMenu &&
                    <HeaderNav active={navActive} className="above-head-navigation-mobile" navItems={aboveNavItems} baseUrl={baseUrl} toggleNav={this.toggleNav} />
                  }
                  <HeaderNav active={navActive} className="navigation" navItems={navItems} baseUrl={baseUrl} toggleNav={this.toggleNav} />
                </nav>
                <div className="controls">
                  <button
                    type="button"
                    className={`control-nav icon-hamburger ${navActive ? 'active' : ''}`}
                    onClick={this.toggleNav}
                    aria-label="Toggle Menu"
                  ><span className={`burger ${navActive ? 'active' : ''}`} /></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      </Headroom>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        abovePrimaryNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "above-primary-navigation" }) {
          items {
            title
            url
            classes
            object_id
            slug: object_slug
            target
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
              target
            }
          }
        }
        primaryNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "primary-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
            object_id
            target
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
              target
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            navigationAbovePrimaryMenu
            socialLinksHeader
            contactNumberHeader
            invertHeaderColours
            showSearchIconInMainNavigation
            locations {
              addressDisplay
              email
              phone
            }
            quote
            quotePage
            showQuoteButtonInHeader
            quotePage
          }
        }
        wordpressWpSettings {
          siteUrl
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
